<script setup>
import { upperFirst, camelCase } from 'lodash-es';

// Props declaration
const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    field: {
        type: Object,
        default: () => ({}),
    },
    position: {
        type: String,
        default: 'above-input',
    },
});

// Method converted into a computed property for better performance
const canShow = computed(() => {
    const className = `verbb\\formie\\positions\\${upperFirst(camelCase(props.position))}`;

    if (props.field.labelPosition === className) {
        return true;
    }

    if (!props.field.labelPosition && props.position === 'above-input') {
        return true;
    }

    return false;
});
</script>

<template>
    <label
        v-if="canShow"
        :for="props.id"
        class="absolute left-4 top-5 block origin-top-left -translate-y-2 scale-50 text-base font-medium uppercase text-cendre transition duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2 peer-focus:scale-50 peer-focus:text-charbon"
    >
        {{ props.field.label }} <span v-if="props.field.required" class="required-marker">*</span>
    </label>
</template>
